function prefetchScripts({ JS, IMG, CSS }) {
  // 对资源列表进行遍历，并创建对应的 link 元素进行预取
  const frag = document.createDocumentFragment()
  const newLink = (href, as) => {
    const link = document.createElement('link')
    link.rel = 'preload'
    link.href = href
    link.as = as
    return link
  }
  JS?.forEach(href => frag.appendChild(newLink(href, 'script')))
  IMG?.forEach(href => frag.appendChild(newLink(href, 'image')))
  CSS?.forEach(href => frag.appendChild(newLink(href, 'style')))
  document.head.appendChild(frag)
}
// 一次性 worker 缓存 5min
function prefetchDomainAbt({ posKeys, newPosKeys }) {
  schttp({
    method: 'post',
    url: '/abt/userinfo?pay_abt=1',
    data: {
      newPosKeys: newPosKeys.join(','),
      posKeys: posKeys.join(',')
    }
  })
}
export const payCardPrefetchFn = (() => {
  if (typeof window === 'undefined') return () => {}
  // TODO 删历史储存，过两版本删掉此行
  localStorage.removeItem('pwa_/ltspc/getAssets/get')
  const CARD_PAYMENT_TYPE = new Set([6, 7])  // 表示是卡支付类型，这里写死很不科学，但是目前没有好办法
  let pay_abt_done = false
  return async (paymentType) => {
    try {
      if (CARD_PAYMENT_TYPE.has(paymentType)) {
        CARD_PAYMENT_TYPE.delete(paymentType)
        const res = await schttp({ url: '/ltspc/getAssetsNew/get', params: { requestFrom: 'pwa' } })
        requestIdleCallback(() => prefetchScripts(res?.info || {}))
        if (!pay_abt_done && res?.info?.ABT) {
          pay_abt_done = true
          prefetchDomainAbt(res.info.ABT)
        }
      }
    } catch (e) {
      console.error('payCardPrefetchFn Error', e)
    }
  }
})()

import { TRANSPORT_TYPE_SHOP } from 'public/src/js/constants'
import { template } from '@shein/common-function'
import abtOther from './abtOther'
import benefit from './benefit'

const getters = {
  ...abtOther,
  ...benefit,
  isCouponAutoUse (state) {
    return !state.is_manual_usedCoupon && ((state.canAutoUsePrime || state.isPrimeMember) || (state.usedXtraCode || state.isCancelXtra))
  },
  first_coupon_code (state) {
    let { autoUse, couponCode = '' } = state.checkout.results?.firstCoupon || {}
    let first_coupon_code = !!(+autoUse) && couponCode
    return first_coupon_code ? couponCode : ''
  },
  isFreePrice (state) {
    return state.price?.actual_shipping_price?.amountWithSymbol && +state.price?.actual_shipping_price?.amount == 0
  },
  // 支付图标
  paymentLogoList (state) {
    return state.paymentLogoInfo?.info?.logoList || []
  },
  storeAddressSite(state) { // 有店配的站点
    return state.locals?.SHOP_TRANSIT?.site
  },
  iconPrefix(state){
    return state.isSSR && !state.prerenderSsr ? 'suiiconfont-critical-checkout' : 'suiiconfont'
  },
  fsIconPrefix(state){
    return state.isSSR && !state.prerenderSsr ? 'suiiconfont-critical' : 'suiiconfont'
  },
  isClient(){
    return typeof window !== 'undefined'
  },
  localLang(state){
    return state.locals?.lang
  },
  isSiteMallList (state) { 
    return state.checkout?.results?.mallInfo?.mallInfoForSite?.length > 1
  },
  communalNotice(state){
    return state.checkout.communalNotice || ''
  },
  serviceType(state) {
    return state.locals?.SERVER_TYPE || gbCommonInfo?.SERVER_TYPE
  },
  showXtra(state) {
    return !!state.xtraProductInfo.product_code && !!state.xtraCouponList.length
  },
  // 当前展示的超省卡信息
  currentSaverProduct(state, getters) {
    const { sheinSaverRenewAbt, xtraProductNormal } = getters.xtraAutoController || {}
    // 不展示续费包：默认包为第一个普通包；展示续费包：默认包为接口返回的第一个包
    let defaultCode = xtraProductNormal[0]?.product_code
    if (sheinSaverRenewAbt) defaultCode = state.xtraProductBagInfo[0]?.product_code
    const code = state.usedXtraCode || defaultCode || ''
    return state.xtraAllProductBagInfo[code] || {}
  },
  // 是否展示超省卡模块
  saverModuleVisible(_, getters) {
    // 有超省卡 code 且 有超省卡券信息
    return getters.currentSaverProduct.productInfo?.product_code && 
      !!getters.currentSaverProduct.couponListInfo?.couponList?.length
  },
  // 沙特高货值信息必填abt
  completeHighValueInformation(state){
    return state.checkoutAbtInfo.CompleteHighvalueInformation?.param || {}
  },
  currencySupport(state){
    return state.checkout.results?.currencySupport || []
  },
  //是否全部为店铺商品
  isAllStore(state, getters){
    return getters.isShopType == 1
  },
  // 0：仅自营；1：仅店铺；2：既有店铺也有自营
  isShopType(state){
    const carts = state.checkout.results?.carts?.carts || []
    const hasStoreGoods = carts.filter(item => item.business_model == 1)
    const isAllStore = carts.length == hasStoreGoods.length
    return hasStoreGoods.length ? isAllStore ? 1 : 2 : 0
  },
  touchRuleInfo(state) {
    // 当前不会存在配置多条的情况，默认取第一个处理
    const ruleList = state.price.touch_rule_list?.filter(item => item.rule_id > 0 && item.hint_type == 6 && !!item.msg) || []

    return ruleList[0] || {}
  },
  taxHint(state, getters) {
    const { tax = [] } = state.price || {}
    const taxList = tax.filter(item => +item.taxPrice?.amount > 0 && item.taxType == 7) || []
    // 当前不会存在配置多条的情况，默认取第一个处理
    const taxPrice = taxList[0]?.taxPrice?.amountWithSymbol || ''
    const taxHint = getters.touchRuleInfo.msg || ''

    return (!!taxList.length && taxHint) ? taxHint.replace('{br_tax_1}', taxPrice) : ''
  },
  primeRightCouponCodeList(state) {
    if(!state.rightConfigList.length) return []
    const freeRight = state.rightConfigList.filter(item => item.right_type_code == 'FREE_SHIPPING_TIMES') || []
    if(!freeRight.length) return []
    return freeRight[0]?.coupon_code_list || []
  },
  // 是否是店配运输方式
  isShopTransit(state) {
    const defaultShipping = state.defaultShipping || []
    if (!defaultShipping.length || !defaultShipping[0]?.shipping_method?.transport_type) return false
    // 店配和宅配互斥,不同mall只能选择其中一种
    const matchShopType = defaultShipping.find(mall => TRANSPORT_TYPE_SHOP.includes(mall.shipping_method.transport_type))
    return !!matchShopType
  },
  forcedShippingByAbt(state) {
    return state.checkout?.abtInfo?.forcedShipping?.param == 'forcedShippingGuarantee'
  },
  switchQsFlagByAbt(state, getters) {
    let switch_qs_flag = 0
    const qsMakeup = state.checkout?.abtInfo?.quickshipFeeSwitch?.param?.qsMakeup || 0
  
    const singleMallCode = state.checkout?.results?.mallInfo?.mallInfoForSite?.[0]?.mall_code || ''
    const filterCarts = state.checkout?.results?.carts?.carts?.filter(item => item.mall_code == singleMallCode) || []
    const quickShipCarts = filterCarts?.filter(item => item.real_quick_ship == 1) || []
    const isAllQuickShip = !!quickShipCarts.length && (filterCarts.length == quickShipCarts.length)
  
    if (getters.showQuickShipByAbt && !isAllQuickShip) switch_qs_flag = qsMakeup
  
    return switch_qs_flag
  },
  userBehaviorAbt(state) {
    return state.checkout?.abtInfo?.UserBehaviorTips?.param || {}
  },
  lowestPriceDays(state) {
    return state.checkout?.abtInfo?.LowestPriceDays?.param || {}
  },
  showShippingInHeaderTitleByAbt(state) {
    // 非US 如果abt 没返回值或者abt异常默认值改成off
    return state.checkout?.abtInfo?.CheckoutHeadShippingEffect?.param.CheckoutHeadShippingEffect || 'off' 
  },
  chemicalsIds(state) {
    let { p65_flag = [] } = state.checkout?.mall_caculate_info || {}
    const p65FlagList = p65_flag?.filter(item => item.flag == 1)
    const skuArr = p65FlagList?.map(item => item.goods_sn)
    return skuArr || []
  },
  shipTimeDesList(state, getters) {
    const shipList = state.mallShippingMethods?.mall_list || []

    return shipList.map(mall => {
      return { 
        mall_code: mall?.mall_code || '', 
        shipping_methods: mall?.shipping_methods.map(method => {
          let shipping_time_desc = 'none'
          if (getters.timePercentAbt) {
            let isShowPercentDes = method.desc_type == 1 ? !!method?.delivery_day_percent_desc : !!method.shipping_day_percent_desc
            let isShowPercentIcon = !!method?.day_percents?.length
            if (isShowPercentDes && isShowPercentIcon) {
              shipping_time_desc = 'desc_and_rule'
            } else if (isShowPercentDes && !isShowPercentIcon) {
              shipping_time_desc = 'desc_only'
            }
          }

          return { transport_type: method.transport_type, shipping_time_desc }
        }) 
      }
    })
  },
  flashSaleGroupList(state) {
    let result = {}
    const cartList = state.checkout?.results?.carts?.carts || []
    cartList.filter(item => item.promotionTypeIds?.some(i => [10, 11].includes(i))).forEach(item => {
      // 默认10普通闪购不会与11直播闪购同时存在于同一商品上
      let flashSalePromotion = item.product.product_promotion_info?.find(promotion => [10, 11].includes(promotion.type_id))
      if(flashSalePromotion){
        if(!result[flashSalePromotion.promotion_id]){
          result[flashSalePromotion.promotion_id] = []
        }
        result[flashSalePromotion.promotion_id].push(item)
      }
    })
    return result
  },
  underPriceGroupList(state) {
    let result = {}
    const cartList = state.checkout?.results?.carts?.carts || []
    cartList.filter(item => item.promotionTypeIds?.includes(30)).forEach(item => {
      let flashSalePromotion = item.product.product_promotion_info?.find(promotion => promotion.type_id == 30)
      if(flashSalePromotion){
        if(!result[flashSalePromotion.promotion_id]){
          result[flashSalePromotion.promotion_id] = []
        }
        result[flashSalePromotion.promotion_id].push(item)
      }
    })
    return result
  },
  discountPriceGroupList(state) {
    let result = {}
    const cartList = state.checkout?.results?.carts?.carts || []
    cartList.filter(item => item.promotionTypeIds?.includes(24)).forEach(item => {
      let flashSalePromotion = item.product.product_promotion_info?.find(promotion => promotion.type_id == 24)
      if(flashSalePromotion){
        if(!result[flashSalePromotion.promotion_id]){
          result[flashSalePromotion.promotion_id] = []
        }
        result[flashSalePromotion.promotion_id].push(item)
      }
    })
    return result
  },
  // 当前产品包/自动用券产品包
  productAutoInfo(state){
    const { pay_member_discount_price = {}, prime_total_price = {} } = state?.checkout?.mall_caculate_info || {}
    const newPrimeProductList = state?.virtualCouponInfo?.newPrimeProductList
    const currentProduct = newPrimeProductList?.find(f => f.product_code === state.usedPrimeCode)?.primeProductList
    const currenctProductCycleDaysObj = newPrimeProductList.find(f => f?.primeProductList?.product_cycle_type === currentProduct?.product_cycle_type && f?.primeProductList?.product_activity_type != 1)
    const currentDaysPriceInfo = currenctProductCycleDaysObj?.primeProductList?.product_price_info || {}
    const discountPrice = +pay_member_discount_price?.amount <= 0 ? {
      ...prime_total_price,
      price: prime_total_price?.amount,
      price_with_symbol: prime_total_price?.amountWithSymbol
    } : {
      ...pay_member_discount_price,
      price: pay_member_discount_price?.amount,
      price_with_symbol: pay_member_discount_price?.amountWithSymbol
    }
    const result = {
      currentProduct,
      currentProductCycleDays: currenctProductCycleDaysObj?.primeProductList,
      limitedPayTypeList: state?.checkout?.results?.primeProductInfo?.limitedPayTypeList || [],
      discountPrice,
      normalShow: +currentDaysPriceInfo?.special_price >= +discountPrice?.price,
      // normalShow: +currentDaysPriceInfo?.special_price < +discountPrice?.price, //mock反
      scence: currenctProductCycleDaysObj?.scence
    }
    // console.log('result===', result)

    return result
  },
  isSelectTokenPay (state) {
    return state.status.cardPay === 'routepay-card' && state.isShowTokenPay && !!Object.keys(state.defaultSelectTokenPay).length
  },
  GBCssRight(state) {
    return state.locals?.GB_cssRight ?? gbCommonInfo?.GB_cssRight
  },
  // quickship
  quickShipCheckoutStatus(state){
    // qs => 只针对单mall场景 
    const singleMallCode = state.checkout?.results?.mallInfo?.mallInfoForSite?.[0]?.mall_code || ''

    const { OrderQuickshipStyle, PlaceOrderQuickshipWord, quickshipFeeSwitch } = state?.checkout?.abtInfo || {}
    const qsMakeup = quickshipFeeSwitch?.param?.qsMakeup || 0
    const cartList = state.checkout?.results?.carts?.carts || []
    const list = state.defaultShipping?.filter(item => item.mall_code == singleMallCode) || []
    const qs_freight_status = list?.[0]?.shipping_method?.qs_freight_status
    const showQsFreight = qs_freight_status == 1 && qsMakeup != 0
    // const quickShipLabelText = state.quickShipInfoTag?.qsDataProps?.text
    const quickShipLabelText = state.qsMallList?.last_quickship_tag || ''
    const result = {
      quickShipStyle: +(OrderQuickshipStyle?.param?.OrderQuickshipStyleStatus || 0),
      PlaceOrderQuickshipWord: +(PlaceOrderQuickshipWord?.param?.PlaceOrderQuickshipWordStatus || 0),
      isAllCartsQuickShip: cartList.every((item) => item?.quick_ship && item?.real_quick_ship == 1),
      isCartsQuickShip: cartList.some((item) => item?.quick_ship && item?.over_quick_ship != 1),
      descQuickShipTime: state.descQuickShipTime,
      quickShipLabelText,
      showQsFreight,
      singleMallCode,
      defaultShipping: state.defaultShipping, // 组装过，只有mall_code 和 mall下对应的运输方式 => []
      mallShippingMethods: state.mallShippingMethods // mall + 运输方式 => defaultShipping 都来自于mallShippingMethods
    }
    // console.log('result==', result)
    return result
  },
  // 法国，波兰金额合规展示
  isSuggested(state) {
    return state.checkout.IS_SUGGESTED || false
  },
  // 德国金额合规展示
  isSuggestedInDe(state) {
    return state.checkout.IS_SUGGESTED === 'special_de'
  },

  cardTokenList(state) {
    const routerPayCardData = (state.checkout?.results?.paymentMethods || [])?.find(item => item?.code === 'routepay-card')
    return routerPayCardData?.card_token_list || []
  },
  lastSelectePayment(state) {
    return state.lastSelectePayment
  },
  // 无地址（宅配）
  isNotHomeAddressScene(state, getters) {
    return getters.isNoAddressEnterCheckoutByAbt && !state.addresslist?.length
  },
  // 无地址（店配）
  isNotStoreAddressScene(state) {
    return state.storeData && state.storeData?.status != 1
  },
  isSelectInstallmentTokenPay (state) {
    return state.status.cardPay === 'routepay-cardinstallment' && state.isShowTokenFrontInstallment
  },
  // 超省卡自动续费产品包
  xtraAutoController(state){
    const xtraPaymentLimit = (state.checkout?.results?.xtraProductInfo?.limitedPayTypeList || []).map(m => m.code)
    const xtraAutoPayLimitInfo = state.checkout?.results?.paymentMethods.filter(item => xtraPaymentLimit?.includes(item.code) && item?.enabled != 0).map(m => m.code)
    const sheinSaverRenewAbt = state.checkout?.abtInfo?.SheinSaverRenew?.param?.SheinSaverRenewStatus == 1
    // 多包，组装后的数据
    const xtraAllProductBagInfo = state.xtraAllProductBagInfo
    const xtraAllproduct = Object.values(xtraAllProductBagInfo).map(m => m.productInfo)
    const xtraProductAuto = xtraAllproduct.filter(f => f.product_activity_type == 1)
    const xtraProductNormal = xtraAllproduct.filter(f => f.product_activity_type != 1)
    const currentPaymenthods = state.status.cardPay
    const currentProduct = xtraAllproduct.find(s => s.product_code === state.usedXtraCode)
    const currentProductCycleDays = xtraAllproduct.find(f => f?.product_cycle_type === currentProduct?.product_cycle_type && f?.product_activity_type != 1)
  
    const result = {
      sheinSaverRenewAbt,
      autoPaymenodsGuideVisible: sheinSaverRenewAbt && !!xtraAutoPayLimitInfo?.length && !!xtraProductAuto?.length, // 前置展示条件，abt/是否有快捷支付/是否有自动续费包
      xtraCurrentAutoPayLimit: xtraAutoPayLimitInfo?.includes(currentPaymenthods), // 当前支付方式是否在快捷支付
      xtraAutoPayLimitInfo, // 自动包能使用的快捷支付方式
      xtraAllproduct, // 所有包
      xtraProductAuto, // 所有自动续费包
      xtraProductNormal, // 所有普通包
      currentProduct, // 当前包
      currentProductCycleDays, // 选中的自动续费超省卡产品同周期的普通包
      currentXtraIsAuto: currentProduct?.product_activity_type == 1, // 当前包是不是自动续费包
    }
    return result
  },
  // EVOLU_SHEIN_ID
  EVOLU_SHEIN_ID(state) {
    return state.checkout?.EVOLU_SHEIN_ID
  },
  largeShipExtendsInfo(state) {
    return state.mallShippingMethods?.mall_list?.map(mall => {
      return {
        mall_code: mall.mall_code,
        // extend_type 时效种类：1、普通时效 2、qs时效 3、海陆运
        aging_extends_info: mall.aging_extends?.find(_ => _.extend_type == 3) || {}
      }
    }) || []
  },
  // 缓存中的本地化尺码所在地区
  cacheLocalCountry(state) {
    return state.locals?.USER_LOCAL_SIZE_COUNTRY || 
      (typeof window !== 'undefined' ? localStorage.getItem('last_select_country') : '')
  },
  // 自研服务的定制商品图片域名列表
  customServiceImgDomains(state) {
    return state.locals?.CUSTOMIZATION_PRODUCT_IMAGE_DOMAINS?.CUSTOM_SERVICE || ['ltwebstatic.com', 'shein.com', 'romwe.com']
  },
  // 第三方服务的定制商品图片域名列表
  thirdPartyServiceImgDomains(state) {
    return state.locals?.CUSTOMIZATION_PRODUCT_IMAGE_DOMAINS?.THIRD_PARTY_SERVICE || ['staticmeow.com/', 'customeow.io/', 'maiyuan.online/']
  },
  SiteUID(state) {
    return state.locals?.SiteUID || gbCommonInfo?.SiteUID || ''
  }
}

export default getters
